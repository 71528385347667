import $ from 'jquery';

let booksZXAngles = [];
let YAngle = 0;

$(() => {
  if ($(".book-3d-scene").length) {
    document.addEventListener('mousemove', onMoveBook);
    document.addEventListener('touchmove', onMoveBook);
    window.addEventListener('scroll', onScrollBook);
    bookRotate();
    onScrollBook();

    $(".book-3d-scene").each(function(index){
      booksZXAngles[index] = [0];
    })
  }
  
});



const onMoveBook = event => {
  const clientX = event.clientX || event.touches && event.touches.length;
  YAngle = ((clientX / $(window).width()) - 0.5) * -50;
  onScrollBook();
  bookRotate();
  
};

const onScrollBook = event => {

    $(".book-3d-scene").each(function(index){

      let bookPosition = $(this).offset().top;
      let zxRotationStartingPoint = bookPosition - $(window).height();
      let zxRotationEndingPoint = bookPosition + $(this).height();
      
      let bDelta = $(window).scrollTop();
      
      // if (bDelta >= zxRotationStartingPoint && bDelta <= zxRotationEndingPoint) { 
        let pointsDiff = zxRotationEndingPoint - zxRotationStartingPoint;
        let relativeDelta = zxRotationEndingPoint - bDelta;
        let dDeltaNormalized = relativeDelta / pointsDiff;
  
        booksZXAngles[index] = dDeltaNormalized * -70 + 30;
        
  
        bookRotate();
  
      //   $(".book-3d").addClass("on-screen");
      // } else {
      //   $(".book-3d").removeClass("on-screen");
      // }
    })
  
  
};

const bookRotate = () => {
  $(".book-3d-scene").each(function(index){
    let XAngle = Math.cos(deg_to_rad(YAngle)) * booksZXAngles[index];
    let ZAngle = Math.sin(deg_to_rad(YAngle)) * booksZXAngles[index];

    $(this).find('.book-3d').css('transform',`rotateY(${YAngle}deg) rotateX(${XAngle}deg) rotateZ(${ZAngle}deg)`);
    
  });
}

const deg_to_rad = (degrees) => {
  var pi = Math.PI;
  return degrees * (pi/180);
}