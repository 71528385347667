import '../styles/main.scss';

import $ from 'jquery';
import likely from 'ilyabirman-likely';

import './video';
import './book3d';

const errors = {
  409: 'У нас уже есть слушатель с такой почтой',
  500: 'Произошла ошибка, мы уже чиним',
};

const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const updateBackgroundPhoto = () => {
  const $background = $('.backgrounds');

  if (!$background.length) return;

  const $photo2 = $('.activate-photo-2');
  const $photo3 = $('.activate-photo-3');

  const shift = $(window).scrollTop() + $(window).height();
  const isPhoto2Active = $photo2.offset().top < shift;
  const isPhoto3Active = $photo3.offset().top < shift;

  $background
    .toggleClass('photo-2-active', isPhoto2Active)
    .toggleClass('photo-3-active', isPhoto3Active);
};

$(() => {
  updateBackgroundPhoto();
  $(window).scroll(updateBackgroundPhoto);

  $('.book-sync-link').hover(
    event => {
      const name = $(event.currentTarget).attr('name');
      $(`.book-sync-link[name=${name}]`).addClass('hover');
    },
    event => {
      const name = $(event.currentTarget).attr('name');
      $(`.book-sync-link[name=${name}]`).removeClass('hover');
    }
  );

  const $payForm = $('form.pay-form');
  const $payInput = $('form.pay-form input#pay_email');
  const $payType = $('form.pay-form input#course_type');
  const $payError = $('.pay-error');
  const $moduleBankForm = $('form.modulebank-form');

  const onPayFormSuccess = data => {
    Object.keys(data || {})
      .map(name => $(`<input type="hidden" name=${name}>`).val(data[name]))
      .forEach(input => $moduleBankForm.append(input));

    $moduleBankForm.submit();
  };

  const onPayFormError = error => {
    $payForm.attr('disabled', true);
    $payForm.toggleClass('disabled', true);
    $payError.toggleClass('visible', true);
    $payError.text(errors[error.status]);
  };

  $payInput.on('input', event => {
    const isValid = validateEmail(event.target.value);

    $payForm.attr('disabled', !isValid);
    $payForm.toggleClass('disabled', !isValid);
    $payError.toggleClass('visible', false);
  });

  $payForm.submit(event => {
    event.preventDefault();

    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: '/pay',
      data: { email: $payInput.val(), type: $payType.val() },
      success: onPayFormSuccess,
      error: onPayFormError,
    });
  });
});

likely.initiate();